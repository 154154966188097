import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export default function Header() {
    const navigate = useNavigate();

    return (
        <StHeader>
            <h2 onClick={() => navigate('/')}>SunsetAuction</h2>
            <StPages>
                <p onClick={() => navigate('/draw')}>영역 지정</p>
                <p onClick={() => navigate('/')}>매물 보기</p>
                <p onClick={() => navigate('/login')}>로그인</p>
            </StPages>
        </StHeader>
    );
}

const StHeader = styled.div`
    position: fixed;
    display: flex;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 80px;
    padding: 0px 30px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    z-index: 10;

    h2 {
        cursor: pointer;
        font-size: 20px;
        font-weight: 700;

        &:hover {
            opacity: 0.7;
        }
    }
`;

const StPages = styled.div`
    display: flex;
    gap: 30px;

    p {
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
`;
