import { styled } from "styled-components";

export const PolygonManage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 60px;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
`;

export const Name = styled.div`
  font-size: 15px;
`;

export const Delete = styled.button`
  width: 30%;
  height: 100%;
`;