import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function GoogleRedirectPage() {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const accessToken = urlParams.get('accessToken');

        if (accessToken && accessToken !== 'null') {
            localStorage.setItem('accessToken', accessToken);
            navigate('/');
        } else {
            navigate('/login');
        }
    }, [navigate]);

    return <div></div>;
}
