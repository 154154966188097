import React from 'react';
import Router from './router/Router';
import { GlobalStyle } from './styles/GlobalStyle';

export default function App() {
    return (
        <>
            <GlobalStyle />
            <Router />
        </>
    );
}
