import { api } from './api';

// export const getPropertiesBySquare = async (lx, rx, ty, by) => {
//     const response = await api.get(
//         `/properties/bysquare/${lx}/${rx}/${ty}/${by}`
//     );
//     return response.data.value.list;
// };

export const getPropertiesWhole = async (lx, rx, ty, by) => {
    const response = await api.post(
        `/properties/whole/${lx}/${rx}/${ty}/${by}`
    );
    return response.data.value.list;
};

export const getPropertiesSigungu = async (lx, rx, ty, by) => {
    const response = await api.post(
        `/properties/sigungu/${lx}/${rx}/${ty}/${by}`
    );
    return response.data.value.list;
};

export const getPropertiesSido = async (lx, rx, ty, by) => {
    const response = await api.post(`/properties/sido/${lx}/${rx}/${ty}/${by}`);
    return response.data.value.list;
};
