/* global kakao */
import React, { useEffect, useState, useRef } from 'react';
import { styled } from 'styled-components';
import PolygonManage from '../components/PolygonManage';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function DrawPage() {
    const mapRef = useRef();
    const [loading, setLoading] = useState(false);
    const managerRef = useRef();
    const [polygons, setPolygon] = useState();
    const [managers, setManager] = useState();
    const [map, setMap] = useState();
    const navigate = useNavigate();
    const [roadView, setRoadView] = useState(false);

    const handleGetPolygonsData = async () => {
        try {
            const { data } = await axios.get(
                'https://api-stg.sunset-auction.xyz/polygons',
                {}
            );
            console.log(data.value.list);
            setPolygon(data.value.list);
        } catch (err) {
            console.error('GET error');
        }

        polygons &&
            polygons.map((polygon) => {
                const linepath = polygon.polygon.coordinates[0].map(
                    (data) => new kakao.maps.LatLng(data[1], data[0])
                );
                var polyline = new kakao.maps.Polygon({
                    path: linepath, // 선을 구성하는 좌표배열 입니다
                    strokeWeight: 3, // 선의 두께입니다
                    strokeColor: '#39f', // 선의 색깔입니다
                    strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                    strokeStyle: 'solid', // 선의 스타일입니다
                    fillColor: '#39f', // 채우기 색깔입니다
                    fillOpacity: 0.35, // 채우기 불투명도 입니다
                });
                polyline.setMap(map);
            });
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.onload = () => {
            kakao.maps.load(() => {
                const container = mapRef.current;
                const options = {
                    center: new kakao.maps.LatLng(37.570701, 126.890667),
                    level: 9,
                };
                const map = new kakao.maps.Map(container, options);
                setMap(map);

                if (roadView == true) {
                    kakao.maps.event.addListener(
                        map,
                        'click',
                        function (mouseEvent) {
                            var latlng = mouseEvent.latLng;
                            console.log(
                                `https://map.kakao.com/link/roadview/${latlng
                                    .getLat()
                                    .toFixed(6)},${latlng.getLng().toFixed(6)}`
                            );
                            window.open(
                                `https://map.kakao.com/link/roadview/${latlng
                                    .getLat()
                                    .toFixed(6)},${latlng.getLng().toFixed(6)}`
                            );
                        }
                    );
                }

                const managerOptions = {
                    map: map,
                    drawingMode: [
                        kakao &&
                            kakao.maps.drawing &&
                            kakao.maps.drawing.OverlayType.POLYGON,
                    ],
                    guideTooltip: ['draw', 'drag', 'edit'],
                    polygonOptions: {
                        draggable: false,
                        removable: false,
                        editable: false,
                        strokeColor: '#39f',
                        fillColor: '#39f',
                        fillOpacity: 0.35,
                        hintStrokeStyle: 'dash',
                        hintStrokeOpacity: 0.5,
                    },
                };
                const manager = new kakao.maps.drawing.DrawingManager(
                    managerOptions
                );
                setManager(manager);
                const data = manager.getData();
                drawPolygon(data.polygon);
                handleGetPolygonsData();

                if (kakao.maps.drawing) {
                    managerRef.current = manager;
                    console.log(managerRef.current);
                } else {
                    console.log('drawing 로딩 안됨');
                }

                const handlePostPolygonsData = async (name, points) => {
                    setLoading(true);
                    var arr = points.map(({ x, y }) => [x, y]);
                    const { x, y } = points[0];
                    arr.push([x, y]);
                    const data = {
                        name: name,
                        polygon: {
                            type: 'Polygon',
                            coordinates: [arr],
                        },
                        description: '임시데이터',
                    };
                    console.log(data);
                    try {
                        const res = await axios.post(
                            'https://api-stg.sunset-auction.xyz/polygons',
                            data,
                            {}
                        );
                        const response = await axios.get(
                            'https://api-stg.sunset-auction.xyz/polygons',
                            {}
                        );
                        setPolygon(response.data.value.list);
                    } catch (err) {
                        alert(
                            '입력 값에 문제가 생겼어요. 입력값을 확인해주세요.'
                        );
                        setLoading(false);
                    }
                    setLoading(false);
                };

                manager.addListener('drawend', async function () {
                    const points =
                        manager.getData().polygon[
                            manager.getData().polygon.length - 1
                        ]?.points;
                    var polygonName = prompt('영역의 이름을 입력하세요!');
                    if (polygonName !== '') {
                        handlePostPolygonsData(polygonName, points);
                    } else {
                        alert('이름을 입력하세요!');
                    }
                });
            });
        };
        //coffee@decacorn.team의 카카오 api 키
        script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_ID}&libraries=services,clusterer,drawing,drawing.MouseEvent`;
        document.head.appendChild(script);
    }, [roadView]);

    function selectOverlay(type) {
        const manager = managerRef.current;
        if (manager && kakao.maps.drawing) {
            // manager와 kakao.maps.drawing이 정의되었는지 확인
            manager.cancel();
            manager.select(kakao.maps.drawing.OverlayType[type]);
        }
    }

    const [currentTypeId, setCurrentTypeId] = useState('');
    const [changeMaptype, setChangeMaptype] = useState('');

    function setOverlayMapTypeId(maptype) {
        // maptype에 따라 지도에 추가할 지도타입을 결정합니다
        if (maptype === 'traffic') {
            setRoadView(false);
            setChangeMaptype(kakao.maps.MapTypeId.TRAFFIC);
        } else if (maptype === 'roadview') {
            setRoadView(true);
            setChangeMaptype(kakao.maps.MapTypeId.ROADVIEW);
        } else if (maptype === 'terrain') {
            setRoadView(false);
            setChangeMaptype(kakao.maps.MapTypeId.TERRAIN);
        } else if (maptype === 'use_district') {
            setRoadView(false);
            setChangeMaptype(kakao.maps.MapTypeId.USE_DISTRICT);
        } else if (maptype === '') {
            setRoadView(false);
            setChangeMaptype('');
            if (map) {
                map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
            }
        } else if (maptype === 'HYBRID') {
            setRoadView(false);
            setChangeMaptype('');
            if (map) {
                map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
            }
        }
    }

    useEffect(() => {
        if (map && currentTypeId) {
            map.removeOverlayMapTypeId(currentTypeId);
        }
        // maptype에 해당하는 지도타입을 지도에 추가합니다
        if (map && changeMaptype) {
            map.addOverlayMapTypeId(changeMaptype);
            setCurrentTypeId(changeMaptype);
        }
    }, [changeMaptype, currentTypeId, map]);

    const drawPolygon = (polygons) => {
        polygons.forEach((polygon) => {
            const path = pointsToPath(polygon.points);
            const style = polygon.options;
            const newPolygon = new kakao.maps.Polygon({
                map: mapRef.current,
                path: path,
                strokeColor: style.strokeColor,
                strokeOpacity: style.strokeOpacity,
                strokeStyle: style.strokeStyle,
                strokeWeight: style.strokeWeight,
                fillColor: style.fillColor,
                fillOpacity: style.fillOpacity,
            });
        });
    };

    const pointsToPath = (points) => {
        return points.map((point) => new kakao.maps.LatLng(point.y, point.x));
    };

    useEffect(() => {
        handleGetPolygonsData();
    }, []);

    useEffect(() => {
        polygons &&
            polygons.map((polygon) => {
                const linepath = polygon.polygon.coordinates[0].map(
                    (data) => new kakao.maps.LatLng(data[1], data[0])
                );
                var polyline = new kakao.maps.Polygon({
                    path: linepath, // 선을 구성하는 좌표배열 입니다
                    strokeWeight: 3, // 선의 두께입니다
                    strokeColor: '#39f', // 선의 색깔입니다
                    strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                    strokeStyle: 'solid', // 선의 스타일입니다
                    fillColor: '#39f', // 채우기 색깔입니다
                    fillOpacity: 0.35, // 채우기 불투명도 입니다
                });
                polyline.setMap(map);
            });
    }, [polygons]);

    return (
        <div style={{ display: 'flex' }}>
            <div>
                <div>
                    <button
                        style={{ width: '120px', height: '30px' }}
                        onClick={() => setOverlayMapTypeId('traffic')}
                    >
                        교통정보
                    </button>
                    <button
                        style={{ width: '120px', height: '30px' }}
                        onClick={() => setOverlayMapTypeId('roadview')}
                    >
                        로드뷰 도로정보
                    </button>
                    <button
                        style={{ width: '120px', height: '30px' }}
                        onClick={() => setOverlayMapTypeId('terrain')}
                    >
                        지형정보
                    </button>
                    <button
                        style={{ width: '120px', height: '30px' }}
                        onClick={() => setOverlayMapTypeId('use_district')}
                    >
                        지적편집도
                    </button>
                    <button
                        style={{ width: '120px', height: '30px' }}
                        onClick={() => setOverlayMapTypeId('')}
                    >
                        일반지도
                    </button>
                    <button
                        style={{ width: '120px', height: '30px' }}
                        onClick={() => setOverlayMapTypeId('HYBRID')}
                    >
                        위성지도
                    </button>
                </div>
                <div
                    id='map'
                    style={{ width: '75vw', height: '100vh' }}
                    ref={mapRef}
                />
            </div>
            <PolygonList
                style={{ display: 'flex', flexderection: '', width: '25vw' }}
            >
                <div style={{ width: '25vw' }}>
                    <Button onClick={() => selectOverlay('POLYGON')}>
                        영역지정
                    </Button>
                </div>
                {polygons &&
                    polygons.map(({ _id, name }, index) => (
                        <PolygonManage
                            key={index}
                            setPolygon={setPolygon}
                            setLoading={setLoading}
                            name={name}
                            id={_id}
                        />
                    ))}
                {loading == true && <div>로딩 중...</div>}
            </PolygonList>
        </div>
    );
}

const Button = styled.button`
    width: 50%;
    height: 60px;
`;

const PolygonList = styled.div`
    display: flex;
    flex-direction: column;
    width: 25vw;
    height: 100vh;
`;
