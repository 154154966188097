/* global kakao */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import axios from 'axios';
import {
    getPropertiesSido,
    getPropertiesSigungu,
    getPropertiesWhole,
} from '../api/propertyApi';

export default function ViewPage() {
    const mapRef = useRef(null);
    let markers = [];
    const [properties, setProperties] = useState([]);
    const [kakaoMap, setKakaoMap] = useState();
    const [selectedPropertyId, setSelectedPropertyId] = useState(null);
    const [polygons, setPolygon] = useState();
    const itemRefs = useRef({});
    const [roadView, setRoadView] = useState(false);
    let wholeProperties = [];
    let sigunguProperties = [];
    let sidoProperties = [];

    const handleGetPolygonsData = async () => {
        try {
            const { data } = await axios.get(
                'https://api-stg.sunset-auction.xyz/polygons',
                {}
            );
            console.log(data.value.list);
            setPolygon(data.value.list);
        } catch (err) {
            console.error('GET error');
        }

        polygons &&
            polygons.map((polygon) => {
                const linepath = polygon.polygon.coordinates[0].map(
                    (data) => new kakao.maps.LatLng(data[1], data[0])
                );
                var polyline = new kakao.maps.Polygon({
                    path: linepath, // 선을 구성하는 좌표배열 입니다
                    strokeWeight: 3, // 선의 두께입니다
                    strokeColor: '#39f', // 선의 색깔입니다
                    strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                    strokeStyle: 'solid', // 선의 스타일입니다
                    fillColor: '#39f', // 채우기 색깔입니다
                    fillOpacity: 0.35, // 채우기 불투명도 입니다
                });
                polyline.setMap(kakaoMap);
            });
    };

    // 현재 지도에 표시된 모든 마커를 제거하는 함수
    const clearMarkers = (markers) => {
        if (markers) {
            markers.forEach((marker) => marker.setMap(null)); // 각 마커를 지도에서 제거
        }
    };

    const fetchAndDisplayProperties = async (map) => {
        const currentLevel = map.getLevel();

        clearMarkers(markers); // 지도 레벨이 변경될 때마다 마커를 초기화

        const bounds = map.getBounds();
        const swLatLng = bounds.getSouthWest();
        const neLatLng = bounds.getNorthEast();

        const lx = swLatLng.getLng();
        const rx = neLatLng.getLng();
        const ty = neLatLng.getLat();
        const by = swLatLng.getLat();

        try {
            if (currentLevel <= 6) {
                wholeProperties = await getPropertiesWhole(lx, rx, ty, by);
                console.log(wholeProperties);
                setProperties(wholeProperties);
                displayMarkers(wholeProperties, map);
            } else if (currentLevel >= 7 && currentLevel <= 8) {
                sigunguProperties = await getPropertiesSigungu(lx, rx, ty, by);
                // console.log(wholeProperties);
                displayMarkers(sigunguProperties, map);
            } else if (currentLevel >= 9) {
                sidoProperties = await getPropertiesSido(lx, rx, ty, by);
                // console.log(wholeProperties);
                displayMarkers(sidoProperties, map);
            }
        } catch (error) {
            console.error('API 요청 중 오류 발생:', error);
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.onload = () => {
            kakao.maps.load(() => {
                const container = mapRef.current;
                const options = {
                    center: new kakao.maps.LatLng(
                        37.551111918342,
                        126.84930138784
                    ),
                    level: 4,
                };
                const map = new kakao.maps.Map(container, options);
                setKakaoMap(map);

                const zoomControl = new kakao.maps.ZoomControl();
                map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

                var mapTypeControl = new kakao.maps.MapTypeControl();
                map.addControl(
                    mapTypeControl,
                    kakao.maps.ControlPosition.TOPRIGHT
                );

                if (roadView == true) {
                    kakao.maps.event.addListener(
                        map,
                        'click',
                        function (mouseEvent) {
                            var latlng = mouseEvent.latLng;
                            console.log(
                                `https://map.kakao.com/link/roadview/${latlng
                                    .getLat()
                                    .toFixed(6)},${latlng.getLng().toFixed(6)}`
                            );
                            window.open(
                                `https://map.kakao.com/link/roadview/${latlng
                                    .getLat()
                                    .toFixed(6)},${latlng.getLng().toFixed(6)}`
                            );
                        }
                    );
                }

                // 초기 데이터 로딩
                fetchAndDisplayProperties(map);
                handleGetPolygonsData();

                // 지도 범위 변경 시 데이터 로딩
                const debouncedFetchData = _.debounce(() => {
                    fetchAndDisplayProperties(map);
                }, 200);

                kakao.maps.event.addListener(
                    map,
                    'bounds_changed',
                    debouncedFetchData
                );
                kakao.maps.event.addListener(
                    map,
                    'zoom_changed',
                    debouncedFetchData
                );
            });
        };
        script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_ID}&autoload=false`;
        document.head.appendChild(script);

        return () => document.head.removeChild(script);
    }, [roadView]);

    useEffect(() => {
        polygons &&
            polygons.map((polygon) => {
                const linepath = polygon.polygon.coordinates[0].map(
                    (data) => new kakao.maps.LatLng(data[1], data[0])
                );
                var polyline = new kakao.maps.Polygon({
                    path: linepath, // 선을 구성하는 좌표배열 입니다
                    strokeWeight: 3, // 선의 두께입니다
                    strokeColor: '#39f', // 선의 색깔입니다
                    strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                    strokeStyle: 'solid', // 선의 스타일입니다
                    fillColor: '#39f', // 채우기 색깔입니다
                    fillOpacity: 0.35, // 채우기 불투명도 입니다
                });
                polyline.setMap(kakaoMap);
            });
    }, [polygons]);

    const displayMarkers = (properties, map) => {
        clearMarkers(markers); // 기존 마커 제거)

        if (properties === wholeProperties) {
            properties.forEach((property) => {
                const position = new kakao.maps.LatLng(
                    property.geoLocation.coordinates[1], // 위도
                    property.geoLocation.coordinates[0] // 경도
                );

                const markerColor =
                    property.source === 'NAVER' ? '#16B75E' : '#278afb';

                // 현재 날짜와 createdAt 날짜의 차이를 계산
                const createdAtDate = new Date(property.createdAt);
                const currentDate = new Date();
                const timeDiff = currentDate - createdAtDate;
                const daysDiff = timeDiff / (1000 * 60 * 60 * 24);

                // 1주일 이내에 생성된 게시물인 경우 "N" 표시 추가
                const isNew =
                    daysDiff <= 7
                        ? `<div style="background-color: black; color: white; position: absolute; top: -6px; left: -6px; border-radius: 50%; padding: 4px 6px 3px 6px; ">N</div>`
                        : '';

                const content = `
        <div class="custom-overlay" style="cursor:pointer; position: relative; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 12px; color: white; width: 100%; height: 100%; padding: 10px; background-color: ${markerColor}; border-radius: 5%; box-shadow: 1px 1px 2px #3b3b3b;" onclick="document.dispatchEvent(new CustomEvent('markerClick', {detail: {id: '${property.geoLocation._id}'}}))">
            ${isNew}
            <div>${property.category}</div>
        </div>`;

                const customOverlay = new kakao.maps.CustomOverlay({
                    position: position,
                    content: content,
                    yAnchor: 1,
                });

                customOverlay.setMap(map);
                markers.push(customOverlay);
            });
        } else if (properties === sigunguProperties) {
            properties.forEach((property) => {
                const position = new kakao.maps.LatLng(
                    property.coordinates[1], // 위도
                    property.coordinates[0] // 경도
                );

                // 클릭 이벤트가 포함된 CustomOverlay의 content 생성
                const content = `<div class="custom-overlay" style="cursor:pointer; display: flex; flex-direction: column; gap: 5px; justify-content: center; align-items: center; font-size: 18px; color: white; width: 100%; height: 100%; padding: 12px 15px; background-color: black; border-radius: 5%; box-shadow: 1px 1px 2px #3b3b3b;"><div>${property.sigungu}</div><div>${property.propertyCount}</div></div>`;

                const customOverlay = new kakao.maps.CustomOverlay({
                    position: position,
                    content: content,
                    yAnchor: 1,
                });

                customOverlay.setMap(map);
                markers.push(customOverlay);
            });
        } else if (properties === sidoProperties) {
            properties.forEach((property) => {
                const position = new kakao.maps.LatLng(
                    property.coordinates[1], // 위도
                    property.coordinates[0] // 경도
                );

                // 클릭 이벤트가 포함된 CustomOverlay의 content 생성
                const content = `<div class="custom-overlay" style="cursor:pointer; display: flex; flex-direction: column; gap: 5px; justify-content: center; align-items: center; font-size: 18px; color: white; width: 100%; height: 100%; padding: 12px 15px; background-color: black; border-radius: 5%; box-shadow: 1px 1px 2px #3b3b3b;"><div>${property.sido}</div><div>${property.propertyCount}</div></div>`;

                const customOverlay = new kakao.maps.CustomOverlay({
                    position: position,
                    content: content,
                    yAnchor: 1,
                });

                customOverlay.setMap(map);
                markers.push(customOverlay);
            });
        }
    };

    useEffect(() => {
        const handleMarkerClick = (event) => {
            const { id } = event.detail;
            setSelectedPropertyId(id);
        };

        document.addEventListener('markerClick', handleMarkerClick);

        return () => {
            document.removeEventListener('markerClick', handleMarkerClick);
        };
    }, []);

    const [currentTypeId, setCurrentTypeId] = useState('');
    const [changeMaptype, setChangeMaptype] = useState('');

    function setOverlayMapTypeId(maptype) {
        // maptype에 따라 지도에 추가할 지도타입을 결정합니다
        if (maptype === 'traffic') {
            setRoadView(false);
            setChangeMaptype(kakao.maps.MapTypeId.TRAFFIC);
        } else if (maptype === 'roadview') {
            setRoadView(true);
            setChangeMaptype(kakao.maps.MapTypeId.ROADVIEW);
        } else if (maptype === 'terrain') {
            setRoadView(false);
            setChangeMaptype(kakao.maps.MapTypeId.TERRAIN);
        } else if (maptype === 'use_district') {
            setRoadView(false);
            setChangeMaptype(kakao.maps.MapTypeId.USE_DISTRICT);
        } else if (maptype === '') {
            setRoadView(false);
            setChangeMaptype('');
            if (kakaoMap.setMapTypeId) {
                kakaoMap.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
            }
        } else if (maptype === 'HYBRID') {
            setRoadView(false);
            setChangeMaptype('');
            if (kakaoMap.setMapTypeId) {
                kakaoMap.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
            }
        }
    }

    useEffect(() => {
        if (kakaoMap && currentTypeId) {
            kakaoMap.removeOverlayMapTypeId(currentTypeId);
        }
        // maptype에 해당하는 지도타입을 지도에 추가합니다
        if (kakaoMap && changeMaptype) {
            kakaoMap.addOverlayMapTypeId(changeMaptype);
            setCurrentTypeId(changeMaptype);
        }
    }, [changeMaptype, currentTypeId, kakaoMap]);

    useEffect(() => {
        if (selectedPropertyId && itemRefs.current[selectedPropertyId]) {
            itemRefs.current[selectedPropertyId].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [selectedPropertyId]);

    const setItemRef = useCallback((id, element) => {
        itemRefs.current[id] = element;
    }, []);

    return (
        <StViewPage>
            <StTypeMapContainer>
                <StMapTypes>
                    <button onClick={() => setOverlayMapTypeId('')}>
                        일반지도
                    </button>
                    <button onClick={() => setOverlayMapTypeId('HYBRID')}>
                        위성지도
                    </button>
                    <button onClick={() => setOverlayMapTypeId('roadview')}>
                        로드뷰 도로정보
                    </button>
                    <button onClick={() => setOverlayMapTypeId('terrain')}>
                        지형정보
                    </button>
                    <button onClick={() => setOverlayMapTypeId('use_district')}>
                        지적편집도
                    </button>
                </StMapTypes>
                <StMapContainer ref={mapRef}></StMapContainer>
            </StTypeMapContainer>
            <StFilterListContainer>
                <StList>
                    {properties.map((property) => (
                        <StItem
                            key={property.geoLocation._id}
                            ref={(element) =>
                                setItemRef(property.geoLocation._id, element)
                            }
                            style={{
                                backgroundColor:
                                    selectedPropertyId ===
                                    property.geoLocation._id
                                        ? '#ebebeb'
                                        : 'transparent',
                            }}
                        >
                            {property.source === 'AUCTION' && (
                                <>
                                    <h3>사건번호 : {property.name}</h3>
                                    <p>종류 : {property.category}</p>
                                    <p>
                                        상세주소 :{' '}
                                        {property.geoLocation.address}
                                    </p>
                                    <p>감정가 : {property.appraisalValue}</p>
                                    <p>진행상태 : {property.auctionStatus}</p>
                                    <p>
                                        최소입찰가 :{' '}
                                        {property.minimumAuctionPrice}
                                    </p>
                                </>
                            )}
                            {property.source === 'NAVER' && (
                                <>
                                    <h3>
                                        매물번호 : {property.name.split(' ')[1]}
                                    </h3>
                                    <p>
                                        <StNaverLink
                                            href={`https://new.land.naver.com/houses?articleNo=${
                                                property.name.split(' ')[1]
                                            }`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            네이버 매물 링크
                                        </StNaverLink>
                                    </p>
                                    <p>
                                        <StAgentLink
                                            href={property.link}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            부동산 링크
                                        </StAgentLink>
                                    </p>
                                    <p>종류 : {property.category}</p>
                                    <p>가격 : {property.dealOrWarrantPrc}</p>
                                    <p>
                                        상세주소 :{' '}
                                        {property.geoLocation.address}
                                    </p>
                                    <p>대지/연면적 : {property.area}</p>
                                    <p>정보제공업체명 : {property.cpName}</p>

                                    <p>중개사 : {property.realtorName}</p>
                                    <p>층 : {property.floorInfo}</p>
                                    <p>향 : {property.direction}</p>
                                    <p>거래유형 : {property.tradeType}</p>
                                </>
                            )}
                        </StItem>
                    ))}
                </StList>
            </StFilterListContainer>
        </StViewPage>
    );
}

const StViewPage = styled.div`
    display: flex;
    height: 100%;
`;

const StTypeMapContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;
const StMapTypes = styled.div`
    display: flex;
    position: fixed;
    z-index: 9;

    button {
        cursor: pointer;
        width: 120px;
        height: 30px;
        padding: 5px 15px;
        background-color: #ffffff;
        border: 1px solid black;
        border-left: none;
        &:hover {
            background-color: #ededed;
        }
    }
`;

const StMapContainer = styled.div`
    width: 80vw;
    height: 100%;
    margin-top: 30px;
`;

const StFilterListContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
`;

const StList = styled.div`
    min-width: 300px;
    max-width: 20vw;
    height: 100%;
    overflow: auto;
`;

const StItem = styled.div`
    padding: 10px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    gap: 8px;

    h3 {
        font-size: 18px;
        font-weight: 650;
    }
`;

const StNaverLink = styled.a`
    text-decoration: none;
    font-weight: bold;
    color: #16b75e;

    &:hover {
        opacity: 0.7;
    }
`;

const StAgentLink = styled.a`
    text-decoration: none;
    font-weight: bold;
    color: #4f4f4f;

    &:hover {
        opacity: 0.7;
    }
`;
