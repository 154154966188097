import styled from 'styled-components';
import React from 'react';
import GOOGLE from '../assets/google.png';

export default function LoginPage() {
    const handleGoogleLogin = () => {
        const googleUrl = process.env.REACT_APP_GOOGLE_URL;
        if (!googleUrl) {
            console.error(
                'Google URL is not defined in the environment variables.'
            );
            return;
        }
        window.location.href = googleUrl;
    };

    return (
        <StLoginPage>
            <StButton onClick={handleGoogleLogin}>
                <StLogo src={GOOGLE} alt='logo' />
                <div>Google Login</div>
            </StButton>
        </StLoginPage>
    );
}

const StLoginPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`;

const StButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 300px;
    height: 70px;
    font-size: 25px;
    font-weight: 550;
    border-radius: 8px;
    border: none;
    background-color: #100033;
    color: white;
    gap: 10px;

    &:hover {
        opacity: 0.7;
    }
`;

export const StLogo = styled.img`
    width: 27px;
    height: 27px;
    flex-shrink: 0;
`;
