import { Route, Routes } from 'react-router-dom';
import React from 'react';
import LoginPage from '../pages/LoginPage';
import DrawPage from '../pages/DrawPage';
import GoogleRedirectPage from '../pages/GoogleRedirectPage';
import ProtectedRoute from './ProtectedRoute';
import ViewPage from '../pages/ViewPage';
import Layout from '../Layout';

export default function Router() {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route
                    path='/'
                    element={
                        // <ProtectedRoute>
                        <ViewPage />
                        // </ProtectedRoute>
                    }
                />
                <Route path='/draw' element={<DrawPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/auth' element={<GoogleRedirectPage />} />
            </Route>
        </Routes>
    );
}
