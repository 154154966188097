import axios from 'axios';

const getAccessToken = () => {
    localStorage?.getItem('jwt');
};

export const api = axios.create({
    baseURL: `https://api-stg.sunset-auction.xyz`,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
